@import url(https://unpkg.com/leaflet@1.0.1/dist/leaflet.css);
@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@500&display=swap);

.leaflet {
    height: 600px;
    width: 100%;

  }
  .leaflet-container{
  height: 600px;
  }
  .react-search-box-dropdown-list-item{
    color:black;
  }
  .leaflet-control-container *{
    z-index:400 ;
  }
li.sc-fzoxKX:nth-child(1)  a, li.sc-fzomME:nth-child(2) a{
  background-color: #3f51b5;
  color:white;
  border-radius:2px;
  margin-right: 4px;
  margin-left: 4px;
}
.leaflet-div-icon{
cursor: pointer;
background: transparent;
border: none;
}
.sc-fznyYp, .sc-fzomME{
  border:1px solid white !important;
  font-family: "Exo 2","Calibri",sans-serif;
} 
.react-search-box-dropdown-list-item{
  font-family: "Exo 2","Calibri",sans-serif;
}
.leaflet-tooltip-pane{
  font-family: "Exo 2","Calibri",sans-serif;
}
.niebieski{
  color:#3f51b5;
}
@media (max-width: 800px) {
  .leaflet, .leaflet-container {
    height: 500px;
    width: 100%;

  }


}

* {
  padding: 0;
  margin: 0;
  outline: none;
}
ul {
  list-style-type: none;
}
